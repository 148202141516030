* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    overflow-x: hidden;
}

.custom-switch.ant-switch-checked {
    background-color: #5D3EAF;
}

.custom-switch.ant-switch:not(.ant-switch-checked) {
    background-color: transparent;
}